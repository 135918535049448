export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["empty"])},
  "language_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lang"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loading"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed"])},
  "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tool"])},
  "action": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actions"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disable"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enable"])},
    "user_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users passwords"])},
    "edu_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["educators passwords"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["close"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clone"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password reset"])}
  },
  "project": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["project"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projects"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["project status"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projects"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no projects yet"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add project"])},
    "unconfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unconfirmed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to project list"])}
  },
  "user": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "educator": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["educator"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["educators"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no educators"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add educator"])}
  },
  "student": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["student"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no students"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["students list"])}
  },
  "task": {
    "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure? Once you confirm the task, you cannot modify it any more. This action cannot be undone."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["task status"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add task"])},
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["task"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasks"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no tasks yet"])},
    "unconfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unconfirmed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabled"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["closed"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to project"])}
  }
}