export default {
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nome"])},
  "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non presente"])},
  "language_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lingua"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caricamento in corso"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiuso"])},
  "tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["strumento"])},
  "action": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azione"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azioni"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestisci"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conferma"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifica"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disabilita"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abilita"])},
    "user_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password utenti"])},
    "edu_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password educatori"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiudi"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clona"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elimina"])},
    "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reset password"])}
  },
  "project": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progetto"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["progetti"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato progetto"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elenco progetti"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ci sono progetti"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuovo progetto"])},
    "unconfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non confermato"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attivo"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disattivato"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna all'elenco progetti"])}
  },
  "user": {
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attivo"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disattivato"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "educator": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["educatore"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["educatori"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ci sono educatori"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuovo educatore"])}
  },
  "student": {
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["studente"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["studenti"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ci sono studenti"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elenco studenti"])}
  },
  "task": {
    "confirm_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attenzione! Una volta confermato, il task non può più essere modificato. Questa azione non può essere annullata."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stato attività"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuova attività"])},
    "sing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attività"])},
    "plur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attività"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non ci sono attività"])},
    "unconfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non confermata"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attiva"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disattivata"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiusa"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna al progetto"])}
  }
}